import React from "react";
import { LinkedinLogo } from "@phosphor-icons/react";
import logo from "./HodgesHoldingsLogo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Hodges Holdings</h1>
        <p>
          Hodges Holdings is an investment company focused on building
          software-driven companies and acquiring strong, profitable businesses
          that could benefit from digital transformation. Our team of
          technology-focused operators and forward-thinking investors are primed
          for incredible opportunities.
        </p>
        <p>
          <small>Get in touch via LinkedIn</small>
        </p>
        <a
          href="https://www.linkedin.com/in/jasonleehodges"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedinLogo size={64} />
        </a>
      </header>
    </div>
  );
}

export default App;
